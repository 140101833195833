import React from "react"
import Container from "@mui/material/Container"
import CssBaseline from "@mui/material/CssBaseline"

const PublicLayout = props => {
  const { route, Component } = props

  const ContainerComponent = (
    <Container component="main" maxWidth="lg">
      <CssBaseline />
      <div >
          <Component route={route}/>
      </div>
    </Container>
  )

  return ContainerComponent
}

export default PublicLayout