const APP_NAME = "2GO Console"

const URL = window.location.host.split(":")[0]

const API_TOKEN = process.env.REACT_APP_INTERNAL_API_TOKEN
const GRAPH_URL = `${process.env.REACT_APP_GRAPHQL_URL}`
// const STATIC_IMAGES_URL = process.env.REACT_APP_IMAGES_URL
// const STATIC_IMAGES_S3_BUCKET = process.env.REACT_APP_IMAGES_S3_BUCKET
// const STATIC_IMAGES_S3_PATH = process.env.REACT_APP_IMAGES_S3_BUCKET_PATH
const ENV = process.env.REACT_APP_ENV
const EDITOR_KEY = "yk63exqmeebbxsh9liab633n1spoyjhxhts5tz26e5c0rxl6"

// console.log( "+++++++++++++++++++++++++++++++" )
// console.log( "URL:", URL )
// console.log( "GRAPH :", GRAPH_URL )
// console.log( "STATIC IMAGE URL:", STATIC_IMAGES_URL )
// console.log( "STATIC IMAGE BUCKET :", STATIC_IMAGES_S3_BUCKET )
// console.log( "STATIC IMAGE BUCKET PATH :", STATIC_IMAGES_S3_PATH )
// console.log( "ENV :", ENV )
// console.log( "API_TOKEN: ", API_TOKEN )
// console.log( "+++++++++++++++++++++++++++++++" )

export {
  APP_NAME,
  URL,
  GRAPH_URL,
  ENV,
  API_TOKEN,
  //STATIC_IMAGES_URL,
  EDITOR_KEY
}