import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useOnceMountEffect } from './UseOnceMountEffect';
import { Checkbox } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ACCEPT_DPA } from '../../auth/api/mutations';
import { useMutation } from '@apollo/client';

export default function DpaDialog() {
    const [open, setOpen] = React.useState(false);
    const [accept, setAccept] = React.useState(false);

    const navigate = useNavigate()

    const [acceptDpaMutation] = useMutation(ACCEPT_DPA)
    const agree = async() => {
        const pk = sessionStorage.getItem("pk")
        const sk = sessionStorage.getItem("sk")
        const { loading } = await acceptDpaMutation({
            variables: {
                pk, sk
            }
        })
        
        if(!loading){
            sessionStorage.setItem("isDpaAccepted", true)
            setOpen(false)
        }
    };

    useOnceMountEffect(() => {
        if (sessionStorage.getItem("isDpaAccepted") === "false") {
            setOpen(true)
        }
    })

    const disagree = () => {
        sessionStorage.clear()
        navigate(`/login`)
    }

    return <Dialog maxWidth="lg"
        open={open}
    >
        <DialogTitle>
            {"Accept 2GO Terms of Use?"}
        </DialogTitle>
        <DialogContent>
            <DialogContentText>
                <div style={{ fontSize: "10pt" }}>
                    <p style={{ fontWeight: "bold", textDecoration: "underline", textAlign: "center" }}>Terms of Use:</p>
                    <p style={{ textAlign: "left" }}>
                        <ol>
                            <li> All users given access must ensure the physical and information security of all 2GO systems given in their custody</li>
                            <li> All users must take reasonable precautions to protect 2GO provided hardware, software and information from theft, damage and misuse</li>
                            <li> All users must not leave their computer and other portable devices unattended while connected to the corporate network. </li>
                            <li> All users must not change any part of the system as to alter in any way the functionality and inherent security of the system.  </li>
                            <li> Only 2GO IT is allowed to make any changes. </li>
                            <li> All 2GO hardware and software being used by users shall be used primarily for business purposes. </li>
                        </ol>
                    </p>
                    <p style={{ fontWeight: "bold", textDecoration: "underline", textAlign: "center" }}>2GO's Group-wide Policy on Using and Handling Personal Data: </p>
                    <p>
                        We at 2GO Group, Inc. (“2GO Group”) respect and protect the privacy of personal data of its employees, clients, customers, business partners, and its stakeholders. We value and recognize the importance of a disciplined compliance with applicable data privacy legislation such as the Data Privacy Act of 2012 (RA No. 10173), its Implementing Rules & Regulations (IRR), and the various issuances of the National Privacy Commission (NPC).
                    </p>
                    <p>
                        This policy prescribes the expected behavior and ethical standards in the collection, handling, use, storage, disclosing, disposal, and otherwise processing of personal data in relation to the conduct of 2GO Group's business.
                    </p>
                    <p>
                        <ol>
                            <li>
                                <span style={{ fontWeight: "bold" }}>PURPOSE: </span><br />
                                This policy outlines 2GO Group's standards when handling or using personal data. This serves as the foundation for all data privacy and personal data protection policies, procedures and guidelines in 2GO.
                            </li>
                            <li>
                                <span style={{ fontWeight: "bold" }}>SCOPE: </span><br />
                                This policy applies to the management and employees of 2GO Group, Inc., and its subsidiaries and affiliates (collectively “2GO”). This policy also applies to third parties that use and handle personal data for 2GO.
                            </li>
                            <li>
                                <span style={{ fontWeight: "bold" }}>POLICY: </span><br />
                                2GO will only process personal data as prescribed in applicable data privacy legislation and in accordance with the data privacy principles of transparency (by properly and sufficiently notifying individuals that their personal will be collected and processed), legitimacy of purpose (by ensuring that the processing of personal data has legal basis), and proportionality (by ensuring that the collection and processing of personal data is appropriate to the declared purposes of processing). When handling, using, or otherwise processing personal data, everyone in 2GO, its employees, and third parties shall adhere to the following:
                                <ol type="A">
                                    <li>
                                        <span style={{ fontWeight: "bold" }}>Respect Data Subject Rights </span><br />
                                        Respect the individual's right to privacy under the law and only process personal with the consent of data subject unless processing without consent is authorized by law.
                                    </li>

                                    <li>
                                        <span style={{ fontWeight: "bold" }}>Compliance with the Law and Internal Policies and Procedures: </span><br />
                                        Ensure that all actions in the use and processing of personal data comply with applicable data privacy legislation. <br />
                                        Adhere to all 2GO Group internal policies and procedures on personal data and information security. <br />
                                        Inform the Data Protection Officer (DPO) if a new system or process will involve the use of personal data before a new system or process is implemented.
                                    </li>
                                    <li>
                                        <span style={{ fontWeight: "bold" }}>Use of Data</span><br />
                                        Only use personal data for the declared intended purposes and do so in a lawful, fair, and ethical manner.
                                    </li>
                                    <li>
                                        <span style={{ fontWeight: "bold" }}>Accuracy</span><br />
                                        Exercise due care to ensure the accuracy of personal data.
                                    </li>
                                    <li>
                                        <span style={{ fontWeight: "bold" }}>Limitation of Retention </span><br />
                                        Retain personal data for as long as necessary or when allowed by law.
                                    </li>
                                    <li>
                                        <span style={{ fontWeight: "bold" }}>Accountability</span><br />
                                        Be accountable for our actions knowing that that failure to adhere to privacy legislations and 2GO policies and procedures pertaining to personal data have accompanying fines, penalties, and disciplinary actions.
                                    </li>
                                    <li>
                                        <span style={{ fontWeight: "bold" }}>Confidentiality</span><br />
                                        Treat personal data with utmost confidentiality. Not disclose personal data to unauthorized persons or entities without a legitimate purpose. Not grant unauthorized persons access to personal data including systems and records that contain personal data.
                                    </li>
                                    <li>
                                        <span style={{ fontWeight: "bold" }}>Protecting Personal Data </span><br />
                                        Protect and ensure the security of personal data that we collect, use, manage, or process and prevent its unauthorized access, loss, alteration, or destruction.<br />
                                        Abide by our legal obligation to immediately notify the Data Protection Officer if we suspect any unauthorized access, loss, alteration or destruction of personal data, or if a personal data breach is detected.
                                    </li>
                                    <li>
                                        <span style={{ fontWeight: "bold" }}>Security in Disposal</span><br />
                                        Only dispose or discard personal data, documents that contain personal data, and hardware that contain personal data in a secure manner to ensure the prevention of unauthorized processing of, access to, or disclosure of personal data.
                                    </li>
                                </ol>
                            </li>
                            <li>
                                <span style={{ fontWeight: "bold" }}>DISCIPLINARY MEASURES: </span><br />
                                Failure to adhere to this policy and other 2GO Group data privacy and information security policies, guidelines, procedures, and processes shall be dealt with in accordance with the 2GO Group Employee Code of Conduct and Discipline.
                            </li>
                            <li>
                                <span style={{ fontWeight: "bold" }}>ESCALATION: </span><br />
                                All employees have the responsibility to escalate all actual and potential data privacy and data protection issues. For questions about this policy, please reach out to the 2GO Data Protection Officer at dataprivacy@2go.com.ph or call 8528-7171 local 80079.
                            </li>
                            <li>
                                <span style={{ fontWeight: "bold" }}>DEVIATION: </span><br />
                                Any request for deviation from, or exemption to, this policy must be made in writing to the policy owner. No deviation or exemption shall be granted unless the same is for legal grounds, exceptional circumstances, or the obvious inapplicability of this policy.
                            </li>
                            <li>
                                <span style={{ fontWeight: "bold" }}>REVIEW, PUBLICATION, AND RELATED POLICIES: </span><br />
                                This Policy shall be reviewed every two (2) years but may be amended or revised anytime depending on the changes or developments in applicable data privacy legislation and in the organization of 2GO Group. <br />
                                This policy shall be published in the official company intranet site and shall be read in conjunction with the 2GO Privacy Manual and other data privacy and information security policies and procedures. Leaders shall be responsible for cascading this policy to team members that do not have access to company email or company intranet.<br />
                                This policy shall be effective until revoked, revised, or superseded.
                            </li>
                        </ol>
                    </p>
                    <p>
                        <Checkbox value={accept} size="small" style={{ width: "20px", padding: 0 }} onClick={() => setAccept(!accept)} /> I hereby acknowledge that I have read and understood the Terms of Use (“TOU”) and the provisions of the Company’s Policy entitled "Using and Handling Personal Data" ("the Policy") in the language that I know, and that I am subject to its provisions. I confirm and understand that my failure to comply with any of the provisions of the TOU and the Policy shall subject me to possible disciplinary action pursuant to the Company's Code of Business Conduct and/or Code of Employee Conduct & Discipline and/or liability and prosecution under applicable laws.
                    </p>
                    <p>
                        I am aware and understand that the Company may update, revise, amend, change, and/or modify the provisions of the TOU and Policy from time to time, and any such updates, revisions, amendments, changes or modifications will be communicated electronically on the Company’s intranet or by other means of communication deemed appropriate by the Company in accordance with law.
                    </p>
                </div>
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button variant='contained' color='info' onClick={ ()=> disagree() }>Disagree</Button>
            <Button variant='contained' autoFocus disabled={!accept} onClick={ ()=> agree() }>Agree</Button>
        </DialogActions>
    </Dialog>
}