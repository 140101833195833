import { lazy } from "react"
const Login = lazy(() => import("../../auth/Login"))

const privateRoutes = [
    {
        Component: Login,
        path: "/login",
    },
    {
        Component: Login,
        path: "/",
    },
]

export default privateRoutes;