import { useApolloClient } from "@apollo/client";
import { Button, Card, CardActions, CardHeader, CircularProgress, FormControl, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from "@mui/material"
import { makeStyles } from "@mui/styles";
import { useEffect, useState } from "react";
import { cardStyle, formControl, StyledTableCell, StyledTableRow, } from "../core/layouts/Common"
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { endOfMonth, format, startOfMonth } from "date-fns";
import { DOWNLOAD_FEEDBACK, SEARCH_FEEDBACK } from "./api/queries";
import MailIcon from '@mui/icons-material/Mail';
import StayPrimaryPortraitIcon from '@mui/icons-material/StayPrimaryPortrait';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    submit: {
        padding: 5
    },
    addVessel: {
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
    image: {
        width: 100,
        height: "auto"
    },
    centered: {
        position: "fixed",
        top: "50%",
        left: "50%",
    },
}));

const Feedback = props => {
    const classes = useStyles()
    const [dateFrom, setDateFrom] = useState(startOfMonth(new Date()))
    const [dateTo, setDateTo] = useState(endOfMonth(new Date()))
    const [feedbacks, setFeedbacks] = useState(null)
    const [evaluationKeys, setEvaluationKeys] = useState([null])
    const [cursor, setCursor] = useState(0)
    const [lastEvaluatedKey, setLastEvaluatedKey] = useState()
    const [isPageLoading, setIsPageLoading] = useState(false)
    const client = useApolloClient()

    const handleStartDateChange = evt => {
        setDateFrom(evt)
    }

    const handleToDateChange = evt => {
        setDateTo(evt)
    }

    const getPrevPage = async () => {
        console.log( evaluationKeys.length, cursor  )
        setCursor( cursor - 1 )
    }

    const getNextPage = async () => {
        if( cursor < evaluationKeys.length ){
            setEvaluationKeys( [ ...evaluationKeys, lastEvaluatedKey ] )
        }
        setCursor( cursor + 1 )
    }


    const download = async () => {
        setIsPageLoading(true)
        try {
            const { data, loading } = await client.query({
                query: DOWNLOAD_FEEDBACK,
                variables: { dateFrom, dateTo },
                fetchPolicy: "network-only",
            })

            if (!loading) {
                setIsPageLoading(false)
                const { downloadCustomerFeedback } = data
                if (downloadCustomerFeedback) {
                    window.location.assign(downloadCustomerFeedback);
                }
            }
        } catch (e) {
            console.error(e)
        }
    }

    useEffect( () => {
        const doSearchFeedBack = async () => {
            setIsPageLoading(true)
            const { data, loading } = await client.query({
                query: SEARCH_FEEDBACK,
                variables: { dateFrom, dateTo, lastEvaluatedKey: evaluationKeys[cursor] },
                fetchPolicy: "network-only",
            })
    
            if (!loading) {
                setIsPageLoading(false)
                const postData = data.getCustomerFeedback
                if (postData) {
                    setFeedbacks(postData.items)
                    setLastEvaluatedKey(postData.lastEvaluatedKey)
                } else {
                    setFeedbacks([])
                    setLastEvaluatedKey(null)
                }
            }
        }

        doSearchFeedBack()
    }, [cursor, client, dateFrom, dateTo, evaluationKeys])

    return <div className={classes.root}>
        <h1>Feedback</h1>
        <Card sx={cardStyle} elevation={0} variant="outlined">
            <CardHeader title="Filter" />
            <FormControl sx={formControl}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <div style={{ display: "flex" }}>
                        <DesktopDatePicker label="From Date"
                            value={dateFrom}
                            onChange={evt => handleStartDateChange(evt)}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </div>
                </LocalizationProvider>
            </FormControl>
            <FormControl sx={formControl}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <div style={{ display: "flex" }}>
                        <DesktopDatePicker label="To Date"
                            value={dateTo}
                            onChange={evt => handleToDateChange(evt)}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </div>
                </LocalizationProvider>
            </FormControl>
        </Card>
        <br />
        {feedbacks && feedbacks.length > 0 && <Card sx={cardStyle} elevation={0} variant="outlined">
            <div style={{ textAlign: "right", width: "100%" }}>
                <Button color="secondary" variant="contained" onClick={() => download()}>
                    Download
                </Button>
            </div>
            <CardHeader title="Results" />
            <TableContainer component={Paper} >
                <Table className={classes.table} >
                    <TableHead>
                        <TableRow >
                            <StyledTableCell>Customer</StyledTableCell>
                            <StyledTableCell>Date</StyledTableCell>
                            <StyledTableCell>Service</StyledTableCell>
                            <StyledTableCell style={{ width: '55%' }}>Feedback</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {feedbacks.map((feedback, i) => (
                            <StyledTableRow key={`r_${i}`} style={{ verticalAlign: 'top' }}>
                                <TableCell component="th" scope="row">
                                    {feedback && <>
                                        <strong>{feedback.customer.firstName} {feedback.customer.lastName}</strong><br />
                                        {feedback.customer.email && <><MailIcon />{feedback.customer.email}<br /></>}
                                        {feedback.customer.mobile && <><StayPrimaryPortraitIcon />{feedback.customer.mobile}<br /></>}
                                    </>}
                                </TableCell>
                                <TableCell component="th" scope="row" align="center">
                                    {format(new Date(feedback.dateSubmitted), "MMM dd, yyyy")}
                                </TableCell>
                                <TableCell component="th" scope="row" align="center">
                                    {feedback.service}
                                </TableCell>
                                <TableCell component="th" scope="row" align="center">
                                    {feedback.feedback}
                                </TableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <CardActions>
                {cursor > 0 && <Button size="small" variant="contained" startIcon={<ArrowBackIosIcon />} onClick={getPrevPage}>Back</Button>}
                {lastEvaluatedKey && <Button size="small" variant="contained" endIcon={<ArrowForwardIosIcon />} onClick={getNextPage} >Next</Button>}
            </CardActions>
        </Card>}
        {isPageLoading && <CircularProgress className={classes.centered} />}
    </div>
}

export default Feedback