import { lazy } from "react"
import Feedback from "../../feedback"
const Dashboard = lazy(() => import("../../dashboard"))
const Profile = lazy(() => import("../../profile"))
const Users = lazy(() => import("../../user"))
const UserInfo = lazy(() => import("../../user/UserInfo"))
const Branch = lazy(() => import("../../branch"))
const BranchInfo = lazy(() => import("../../branch/BranchInfo"))
const Vessel = lazy(() => import("../../vessel"))
const VesselInfo = lazy(() => import("../../vessel/VesselInfo"))
const Port = lazy(() => import("../../port"))
const PortInfo = lazy(() => import("../../port/PortInfo"))
const Accommodation = lazy(() => import("../../accomodation"))
const AccomodationInfo = lazy(() => import("../../accomodation/AccomodationInfo"))
const Widget = lazy(() => import("../../widget"))
const WidgetInfo = lazy(() => import("../../widget/WidgetInfo"))
const ContactUs = lazy(() => import("../../contactUs"))

const privateRoutes = [
    {
        Component: Dashboard,
        path: "/dashboard",
    },
    {
        Component: Profile,
        path: "/profile",
    },
    {
        Component: Users,
        path: "/users",
    },
    {
        Component: UserInfo,
        path: "/user-info/:userPk",
    },
    {
        Component: Port,
        path: "/ports",
    },
    {
        Component: BranchInfo,
        path: "/branch-info/:branchPk",
    },
    {
        Component: PortInfo,
        path: "/create-port",
    },
    {
        Component: PortInfo,
        path: "/port-info/:portPk",
    },
    {
        Component: UserInfo,
        path: "/create-user",
    },
    {
        Component: VesselInfo,
        path: "/vessel-info/:vesselPk",
    },
    {
        Component: VesselInfo,
        path: "/create-vessel",
    },
    {
        Component: VesselInfo,
        path: "/create-info/:vesselPk",
    },
    {
        Component: Branch,
        path: "/branches",
    },
    {
        Component: Vessel,
        path: "/vessels",
    },

    {
        Component: Widget,
        path: "/widget",
    },
    {
        Component: WidgetInfo,
        path: "/create-widget",
    },
    {
        Component: WidgetInfo,
        path: "/widget-info/:widgetPk",
    },
    {
        Component: Accommodation,
        path: "/accommodation",
    },
    {
        Component: AccomodationInfo,
        path: "/create-accommodation",
    },
    {
        Component: AccomodationInfo,
        path: "/accommodation-info/:accomodationPk",
    },
    {
        Component: ContactUs,
        path: "/contact-us",
    },
    {
        Component: Feedback,
        path: "/customer-feedback",
    }
]

export default privateRoutes;