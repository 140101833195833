import { createTheme } from "@mui/material/styles";
import { deepOrange } from "@mui/material/colors";

const theme = createTheme({
  typography: {
    allVariants: {
      fontFamily: "'HenrySansRegular'",
    },
  },
  palette: {
    primary: {
      main: "#EC008C",
      contrastText: "#fff",
    },
    secondary: {
      main: "#692E87",
      contrastText: "#fff",
    },
  },
});

export default theme;
