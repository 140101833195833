import React from "react"
import { BrowserRouter, Route, Routes } from "react-router-dom"
import privateRoutes from "../routes/privateRoutes"
import publicRoutes from "../routes/publicRoutes"
import PrivateLayout from "./PrivateLayout"
import PublicLayout from "./PublicLayout"

const MasterLayout = () => {

     return (
          <BrowserRouter>
               <Routes>
                    {privateRoutes.map(({ Component, path, props, isPlain }) => {
                         return (
                              <Route path={path} {...props}
                                   key={path}
                                   element={<PrivateLayout Component={Component} {...props} isPlain={ isPlain } />}
                              />
                         );
                    })}
                    {publicRoutes.map(({ Component, path, props, isPlain }) => {
                         return (
                              <Route path={path} {...props}
                                   key={path}
                                   element={<PublicLayout Component={Component} {...props} isPlain={ isPlain } />}
                              />
                         );
                    })}
               </Routes>
          </BrowserRouter>
     )
}

export default MasterLayout
