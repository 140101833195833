import { gql } from "@apollo/client";

export const SEARCH_FEEDBACK = gql`
  query getCustomerFeedback( $dateFrom: AWSDateTime, $dateTo: AWSDateTime, $lastEvaluatedKey: AWSJSON ) {
    getCustomerFeedback( dateFrom: $dateFrom, dateTo: $dateTo, lastEvaluatedKey: $lastEvaluatedKey ){
      items {
        pk
        sk
        dateSubmitted
        feedback
        service
        customer {
          firstName
          lastName
          email
          mobile
        }
      }
      lastEvaluatedKey
    }
  }
`
export const DOWNLOAD_FEEDBACK = gql`
  query downloadCustomerFeedback( $dateFrom: AWSDateTime, $dateTo: AWSDateTime ) {
    downloadCustomerFeedback( dateFrom: $dateFrom, dateTo: $dateTo )
  }
`

