import { ApolloClient, InMemoryCache, from, HttpLink, ApolloLink } from "@apollo/client";
import { GRAPH_URL, API_TOKEN } from "../../../constants"

const httpLink = new HttpLink({ uri: GRAPH_URL });

let token = null

const getSavedToken = () => {
  if( token ){
    return token
  } 

  token = sessionStorage.getItem("token")
  return token || "2go-pub"
}

const authMiddleware = new ApolloLink((operation, forward) => {
  // add the authorization to the headers
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      "Authorization": API_TOKEN || null,
      token: `${getSavedToken()}`
    }
  }));

  return forward(operation);
})


const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: from([
    authMiddleware,
    httpLink
  ]),
});

export default client
