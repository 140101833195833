import { gql } from "@apollo/client";

export const LOGIN = gql`
    mutation login( $email: String!, $password: String! ){
        login( email:$email, password:$password  ){
            refreshToken
            accessToken
        }
    }
`
export const FORGOT_USER = gql`
    mutation forgotPassword( $email: String ){
        forgotPassword( email:$email ){
            success
        }
    }
`

export const ACCEPT_DPA = gql`
    mutation acceptDpa( $pk: String, $sk: String ){
        acceptDpa( pk:$pk, sk:$sk ){
            success
        }
    }
`